import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";
import iconUltrafast from './../../assets/image/rebrand/icon-ultrafast.svg';
import imageWifi from './../../assets/image/rebrand/icon-wifi.svg';
import iconReliable from './../../assets/image/rebrand/icon-reliable.svg';
import iconFuture from './../../assets/image/rebrand/icon-future.svg';

const FeatureCard = ({
    color = "primary",
    imgUrl,
    title,
    children,
    ...rest
}) => (
    <div className="text-center mt-5">
        <img alt="" src={imgUrl}  className="mb-3" height={69}/>
        <Text
            color="white"
            as="h3"
            fontSize={5}
            fontWeight={500}
            mb={3}
        >
            {title}
        </Text>
        <Text color="white" style={{  lineHeight: 1.5, fontSize: '1.2em' }}>
            {children}
        </Text>
    </div>
);

const WhyGoFullFibre = () => {

    const data = useStaticQuery(graphql`
        query strapiWGFQuery {
            strapiWhyGoFullFibreSection {
                title_line_1
                title_line_2
                subtitle_text
                card_title_1
                card_text_1
                card_title_2
                card_text_2
                card_title_3
                card_text_3
            }
        }      
    `);

    const pageData = getProperty(data, 'strapiWhyGoFullFibreSection');

    return (
        <>
            <Section style={{ background: 'linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%)' }}>
                <Container>
                    <Row>
                        <Col sm="12" className="text-center">
                            <img src={imageWifi} width={350} className="mb-5"/>
                            <Title color="white">{pageData.title_line_1}<br />{pageData.title_line_2}</Title>
                            <Row>
                                <Col lg="8" className="centered">
                                    <Text color="white">{pageData.subtitle_text}</Text>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg="4">
                            <FeatureCard
                                color="primary"
                                imgUrl={iconUltrafast}
                                title={pageData.card_title_1}
                            >
                                {pageData.card_text_1}
                            </FeatureCard>
                        </Col>
                        <Col lg="4">
                            <FeatureCard
                                color="primary"
                                imgUrl={iconReliable}
                                title={pageData.card_title_2}
                            >
                                {pageData.card_text_2}
                            </FeatureCard>
                        </Col>
                        <Col lg="4">
                            <FeatureCard
                                color="primary"
                                imgUrl={iconFuture}
                                title={pageData.card_title_3}
                            >
                                {pageData.card_text_3}
                            </FeatureCard>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default WhyGoFullFibre;
