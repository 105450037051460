import React from "react";
import styled from "styled-components";
import {Container, Row, Col, Image} from "react-bootstrap";
import {Title, Section, Text, Button, Box} from "../../components/Core";
import {device} from "../../utils";
import yflogo from "../../assets/image/svg/yf-logo.svg"
import yflogoWhite from "../../assets/image/svg/yf-logo-white.svg"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";

const FormStyled = styled.div`
  @media ${device.sm} {
        padding: 25px;
    }
    padding: 25px;
    box-shadow: ${({theme}) => `0 20px 61px ${theme.colors.shadow}`};
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.light};
    width: 100%;
`;

const StyledRow = styled(Row)`
    padding: 50px 40px 40px 40px;
    background-color: #000;
    border-radius: 30px;
`;

const NetomniaProviders = () => {

    const data = useStaticQuery(graphql`
        query strapiHomepageProvidersQuery {
            strapiHomepageProvidersSection {
                title
                subtitle
                card_title
                card_text
                button_text
            }
        }      
    `);

    const pageData = getProperty(data, 'strapiHomepageProvidersSection');

    return (
        <>
            <Section bg="#fdf5f9" className="pt-5">
                <Container>
                    <StyledRow>
                        <Col lg="6" className="text-left">
                            <Title variant="news" className="pink-color">{pageData.title}</Title>
                            <Text className="white-color pb-3">
                                {pageData.subtitle}
                            </Text>
                        </Col>
                        <Col lg="6" className="text-left align-self-center">
                            <Image alt="" src={yflogoWhite} width={200} />
                            <h4 className="mt-4 mb-3 white-color">{pageData.card_title}</h4>
                            <Text className="white-color">{pageData.card_text}</Text>
                            <a href="https://www.youfibre.com/?utm_source=In+Build&utm_medium=Button&utm_campaign=Postcode+Check" target="_blank">
                                <Button className="mt-2">
                                    {pageData.button_text}
                                </Button>
                            </a>
                        </Col>
                    </StyledRow>
                </Container>
            </Section>
        </>
    )
}

export default NetomniaProviders
