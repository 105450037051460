import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import {
    Title,
    Section,
    Text
} from "../../components/Core";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";
import CheckAvailability from "../../sections/index/CheckAvailability";
import homepageHero from "./../../assets/image/rebrand/homepage-hero.jpg";
import gradientDot from "./../../assets/image/rebrand/gradient-dot.png";

const AbsoluteContainer = styled(Container)`
    position: absolute;
    left: 0;
    right: 0;
    background-color: transparent;
`;

const HomepageHero = styled(Section)`
    position: relative;
    padding-top: 0;
    margin-top: 100px;
    padding-bottom: 100px;
    background-color: #fdf5f9;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        .img-fluid {
            max-width: none;
            height: 500px;
            width: auto !important;
        }
    }
`;

const StyledTitle = styled(Title)`
    img {
        padding-top: 20px;
        margin-left: 5px;
    }
`;

const Hero = () => {
    const data = useStaticQuery(graphql`
        query strapiHomepageHeroSectionQuery {
            strapiHomepageHeroSection {
                title_1
                title_2
                subtitle
                button_text
            }
        }
    `);

    const pageData = getProperty(data, 'strapiHomepageHeroSection');

    return (
        <HomepageHero>
            <img src={homepageHero} alt="Hero" className="img-fluid w-100" />
            <AbsoluteContainer>
                <Row>
                    <Col lg={6} md={8} xs={11} className="centered">
                        <StyledTitle className="text-left white-color" style={{ margin: 0 }}>{pageData.title_1}<img src={gradientDot} width={12}/></StyledTitle>
                        <StyledTitle className="text-right white-color" style={{ margin: 0 }}>{pageData.title_2}<img src={gradientDot} width={12}/></StyledTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="centered text-center mt-3">
                        <Text color="white" style={{ lineHeight: 1.2 }}>
                            {pageData.subtitle}
                        </Text>
                        <CheckAvailability
                            focusInput={false}
                            hideLogo={true}
                            customTitle={""}
                            customSubtitle={""}
                        />
                    </Col>
                </Row>
            </AbsoluteContainer>
        </HomepageHero>
    );
};

export default Hero;
