import React from "react";
import {Container, Row, Col, Image} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import {Title, Section, Text} from "../../components/Core";
import adslSVG from "../../assets/image/svg/adsl.svg"
import fttcSVG from "../../assets/image/svg/fttc.svg"
import fttpSVG from "../../assets/image/svg/fttp.svg"
import {sanitizeTextForLineBreaks} from "../../utils/helperFn";

const FeatureTitle = styled(Text)`
    font-size: 1.3em;
    color:black;
    font-weight: bold;
`;

const FeatureContent = styled(Text)`
    line-height: 1.75;
    font-size: 1.2em;
`;

const StyledBox = styled(Col)`
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
    padding: 40px;
    margin-top: 50px;
`

const ConnectivityConfusion = () => {

    const data = useStaticQuery(graphql`
        query ConnectivityConfusionQuery {
        strapiConfusionSection {
            feature_1_content
            feature_1_title
            feature_2_content
            feature_2_title
            feature_3_content
            feature_3_title
            title
            subtitle
        }
    }`
    )

    const pageElements = data.strapiConfusionSection

    return (
        <Section bg="#fdf5f9" className="position-relative pt-0">
            <Container>

                <Row className="align-items-center">
                    <Col sm={12} className="text-center">
                        <Title>{pageElements.title}</Title>
                        <Text mb={4}>
                            {pageElements.subtitle}
                        </Text>
                    </Col>
                </Row>

                <Row>
                    <StyledBox sm={12}>
                        <Row>
                            <Col lg={6}>
                                <FeatureTitle className="text-center">The Good</FeatureTitle>
                                <Image alt="" src={fttpSVG} width={524} height={174} style={{ maxWidth:"100%" }}/>
                            </Col>
                            <Col lg={6} className="pl-4 align-self-center">
                                <FeatureTitle className="text-left">
                                    {pageElements.feature_3_title}
                                </FeatureTitle>
                                <FeatureContent>
                                    {sanitizeTextForLineBreaks(pageElements.feature_3_content)}
                                </FeatureContent>
                            </Col>
                        </Row>
                    </StyledBox>

                    <StyledBox sm={12}>
                        <Row>
                            <Col lg={6}>
                                <FeatureTitle className="text-center">The Bad</FeatureTitle>
                                <Image alt="" src={fttcSVG} width={524} height={174} style={{ maxWidth:"100%" }}/>
                            </Col>
                            <Col lg={6} className="pl-4 align-self-center">
                                <FeatureTitle className="text-left">
                                    {pageElements.feature_2_title}
                                </FeatureTitle>
                                <FeatureContent>
                                    {sanitizeTextForLineBreaks(pageElements.feature_2_content)}
                                </FeatureContent>
                            </Col>
                        </Row>
                    </StyledBox>

                    <StyledBox sm={12}>
                        <Row>
                            <Col lg={6}>
                                <FeatureTitle className="text-center">The Ugly</FeatureTitle>
                                <Image alt="" src={adslSVG} width={524} height={174} style={{ maxWidth:"100%" }}/>
                            </Col>
                            <Col lg={6} className="pl-4 align-self-center">
                                <FeatureTitle className="text-left">
                                    {pageElements.feature_1_title}
                                </FeatureTitle>
                                <FeatureContent>
                                    {sanitizeTextForLineBreaks(pageElements.feature_1_content)}
                                </FeatureContent>
                            </Col>
                        </Row>
                    </StyledBox>
                </Row>
            </Container>
        </Section>
    )
}



export default ConnectivityConfusion;
