import React, { useEffect, useContext } from "react";
import Hero from "../sections/index/Hero";
import WhyGoFullFibre from "../sections/index/WhyGoFullFIbre";
import HomepageRegions from "../sections/index/HomepageRegions";
import NetomniaProviders from "../sections/index/NetomniaProviders";
import PageWrapper from "../components/PageWrapper";
import BuildingFeatures from "../sections/index/BuildingFeatures";
import ConnectivityConfusion from "../sections/index/ConnectivityConfusion";
import Seo from "./../components/Seo";
import { findUtmParams } from "./../utils/helperFn";
import GlobalContext from "../context/GlobalContext";
import CheckAvailability from "../sections/index/CheckAvailability";

const LandingPage = (props) => {

    const gContext = useContext(GlobalContext)

    if (findUtmParams(props.location.search)) {
        if (typeof window !== 'undefined' && window) {
            localStorage.setItem('utmData', JSON.stringify(findUtmParams(props.location.search)));
        }
    }

    useEffect(() => {
        gContext.goResetRegistrationData()
    }, [])

    return (
        <>
            <Seo page="index" />
            <PageWrapper>
                <Hero />
                <ConnectivityConfusion />
                <WhyGoFullFibre />
                <HomepageRegions />
                <NetomniaProviders />
                <BuildingFeatures />
                <CheckAvailability
                    focusInput={false}
                    className="homepage-check-availability"
                    minimized={true}
                    hideLogo={true}
                    customTitle={"Check your availability today"}
                    customSubtitle={""}
                />
            </PageWrapper>
        </>
    );
};
export default LandingPage;
