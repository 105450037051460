import React from "react";
import {Container, Row, Col, Image} from "react-bootstrap";
import {Title, Section, Box, Text} from "../../components/Core";
import styled from "styled-components";
import netomniaWorkers from "../../assets/image/jpeg/netomnia-workers.jpg"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";

import number1 from '../../assets/image/rebrand/number-1.svg';
import number2 from '../../assets/image/rebrand/number-2.svg';
import number3 from '../../assets/image/rebrand/number-3.svg';

import cheltenham from '../../assets/image/rebrand/cheltenham.png';
import circleGradient from '../../assets/image/rebrand/circle-gradient.png';

const WidgetStyled = styled(Box)`
  padding-right: 30px;
  padding-bottom: 20px;
  display: flex;
  align-items: baseline;
`;

const Widget = ({title = "", src, children = "", ...rest}) => {
    return (
        <WidgetStyled {...rest}>
            <img src={src} alt="" width={30} className="mb-4 mr-2"/>
            <div>
                <Title style={{color:'black', fontSize:'2em', lineHeight:'1.1em'}} className="mb-2">
                    {title}
                </Title>
                <Text variant="small" style={{color: 'black', fontSize: '1.2em', lineHeight:'1.6em'}}>{children}</Text>
            </div>
        </WidgetStyled>
    );
};


const BuildingFeatures = () => {

    const data = useStaticQuery(graphql`
        query strapiHomepageFeaturesQuery {
            strapiHomepageFeaturesSection {
                features_image {
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                item_title_1
                item_text_1
                item_title_2
                item_text_2
                item_title_3
                item_text_3
            }
        }      
    `);

    const pageData = getProperty(data, 'strapiHomepageFeaturesSection');

    return (
        <>
            <Section className="pt-0 pb-0">
                <Container>
                    <Row className="align-items-end">
                        <Col lg={6} className="pt-100 pb-100">
                            <Widget title={pageData.item_title_1} mb={3} src={number1}>
                                {pageData.item_text_1}
                            </Widget>
                            <Widget title={pageData.item_title_2} mb={3} src={number2}>
                                {pageData.item_text_2}
                            </Widget>
                            <Widget title={pageData.item_title_3} mb={3} src={number3}>
                                {pageData.item_text_3}
                            </Widget>
                        </Col>
                        <Col lg={6}>
                            <img src={circleGradient} alt="" style={{position: 'absolute', zIndex: -1}}/>
                            <Image alt="" src={cheltenham} className="img-fluid" style={{ borderRadius: '6px' }} width={634} height={558}/>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
}

export default BuildingFeatures;
