import React, { useRef, useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import UKRegions from "../../assets/map/uk-regions/uk-regions";
import Cities from "../../assets/map/uk-regions/Cities";
import { RadioSVGMap } from "react-svg-map";
import {Section, Text, MainTitle} from "../../components/Core";
import GlobalStats from "../cities/GlobalStats";
import { TownCard, MoreCardButton } from "../../components/TownCard/TownCard";
import "../../assets/map/map.scss";
import {getProperty} from "../../utils/helperFn";
import GlobalContext from "../../context/GlobalContext";

const HomepageRegions = () => {
    const gContext = useContext(GlobalContext)


    const data = useStaticQuery(graphql`
       query getHomepageAvailableRegionsQuery {
         strapiAreasPage {
            title
            subtitle
          }
          allStrapiRegions(filter: {towns: {elemMatch: {id: {gt: 0}}}}) {
            edges {
              node {
                  id
                  Slug
                  Name
              }
            }
          }
          allStrapiTowns {
            edges {
              node {
                Name
                Slug
                Featured_image{
                    childImageSharp {
                        fluid(maxHeight: 80, quality: 85) {
                          ...GatsbyImageSharpFluid
                        }
                    }
                }
                region {
                    Name
                    Slug
                }
              }
            }
          }
          strapiPageHeadings(slug: {eq: "areas"}) {
                h1
            }
        }
    `)

    useEffect(() => {
        gContext.goResetRegistrationData()
    }, [])

    const [selectedLocationId, setSelectedLocationId] = useState('north-east')
    const mapResultsRef = useRef()
    const pageElements = data.strapiAreasPage
    const netomniaRegions = data.allStrapiRegions.edges.map(strapiRegion => strapiRegion.node)
    const netomniaTowns = data.allStrapiTowns.edges.map(strapiTown => strapiTown.node)
    const headingsData = getProperty(data, 'strapiPageHeadings');
    const currentRegion = netomniaRegions.find(x => x.Slug === selectedLocationId);

    const allUkRegions = UKRegions.mapData.map(region => ({
        name: region.name,
        id: region.id,
        path: region.path,
    }))

    const map = {
        "label": "Map of UK Regions",
        "viewBox": "-679.3 146.4 327.7 370.6",
        "locations": allUkRegions
    }

    const handleMapClick = (id) => {
        if (netomniaTowns.find(town => town.region.Slug === id)) {
            setSelectedLocationId(id);
        }
    }

    const renderMapFilter = () => {

        const townsInRegion = netomniaTowns.filter(town => (
            town.region.Slug === selectedLocationId
        ))

        const renderTown = (town) => (
            <Col className="col-12 mt-3" key={town.Slug}>
                <TownCard
                    townImg={town.Featured_image}
                    title={town.Name}
                    link={town.Slug}
                    regionName={town.region.Name}
                ></TownCard>
            </Col>
        )


        return (
            <>
                <Row className="mt-3" ref={mapResultsRef}>
                    {
                        townsInRegion.slice(0, 3).map(town => renderTown(town))
                    }
                </Row>
                {townsInRegion.length > 3 && <MoreCardButton areaLink={selectedLocationId}/>}
            </>
        )
    }

    const getLocationClassName = (location) => {
        return netomniaRegions.find(x => x.Slug === location.id) ? 'svgregion active' : 'svgregion disabled'
    }
    return (
        <>
            <Section className="position-relative">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={5} className="pb-3">
                            <MainTitle className="text-left">{headingsData.h1}</MainTitle>
                            <Text className="mt-3 text-left">
                                {pageElements.subtitle}
                            </Text>
                            <Text className="mb-5 text-left" color="dark">
                                <b>Click on the map to find out more about our plans.</b>
                            </Text>
                            {renderMapFilter()}
                        </Col>
                        <Col lg={7} className="pb-3 justify-content-center justify-content-lg-end">
                            <div className="mt-3 mt-lg-4 interactive-map-wrapper">
                                <RadioSVGMap
                                    map={map}
                                    selectedLocationId={selectedLocationId}
                                    locationClassName={getLocationClassName}
                                    onChange={(e) => handleMapClick(e.id)}
                                    childrenAfter={<Cities filterData={null} />}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section bg="#fdf5f9" className="pb-0">
                <GlobalStats />
            </Section>
        </>
    )
}

export default HomepageRegions;
